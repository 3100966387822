/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"

const useKlaroManager = () => {
  const [manager, setManager] = useState(null)
  const [klaroReady, setKlaroReady] = useState(false)

  useEffect(() => {
    if (window) {
      let klaroManager = null
      try {
        if (window.klaro && window.klaroConfig) {
          klaroManager = window.klaro.getManager()
        } else {
          throw "klaro not found"
        }
      } catch (error) {
        klaroManager = null
        // polling until klaro is initialized
        window.klaroInterval = window.setInterval(function () {
          try {
            if (window.klaro && window.klaroConfig) {
              klaroManager = window.klaro.getManager()
            } else {
              throw "klaro not found"
            }
          } catch (e) {
            klaroManager = null
          } finally {
            if (klaroManager) {
              clearInterval(window.klaroInterval)
              setManager(klaroManager)
              setKlaroReady(true)
            }
          }
        }, 500)
      } finally {
        if (klaroManager) {
          setManager(klaroManager)
          setKlaroReady(true)
        }
      }
    }
  }, [])
  return {
    manager,
    klaroReady,
  }
}

export default useKlaroManager
