/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"

import useKlaroManager from "./useKlaroManager"

const useKlaroConsentable = (appKey) => {
  const KLARO_APP_KEY = appKey
  const { manager, klaroReady } = useKlaroManager()
  const { i18n } = useTranslation()
  const [usageConsented, setUsageConsented] = useState(null)
  const [inlineConsented, setInlineConsented] = useState(null)
  const [appInfo, setAppInfo] = useState(null)

  const handleKlaroConsent = (e) => {
    manager.updateConsent(KLARO_APP_KEY, true)
    manager.saveAndApplyConsents()
    setInlineConsented(true)
  }

  useEffect(() => {
    if (manager && klaroReady) {
      let renderAllowed = manager.getConsent(KLARO_APP_KEY)
      if (renderAllowed) {
        setUsageConsented(true)
      } else {
        if (window && window.klaro && window.klaroConfig) {
          let klaroTrans = window.klaro.getConfigTranslations(
            window.klaroConfig
          )
          setAppInfo(klaroTrans.get(i18n.language).get(KLARO_APP_KEY))
        }
        setUsageConsented(false)
      }
    }
  }, [klaroReady, manager, inlineConsented])

  return {
    klaroReady,
    usageConsented,
    appInfo,
    handleKlaroConsent,
  }
}

export default useKlaroConsentable
