import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Video from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Video"

const VideoHeroBlock = ({ block }) => {
  return (
    <BlockWrapper
      block={block}
      blockPadding=""
      blockWidth="screen"
      showHeadline="false"
      className="bg-black"
    >
      {block.full_screen ? (
        <div className="relative">
          {block.headline?.length > 0 && (
            <div className="absolute inset-x-0 top-0 z-30 w-full ">
              <div className="max-w-screen-xl mx-auto">
                <h1 className="pt-10 text-white shadow-sm headline headline-h1 px-grid">
                  {block.headline}
                </h1>
              </div>
            </div>
          )}
          <Video
            ratio="16by9"
            video_url={
              block.video_url.includes("vimeo.com")
                ? block.video_url + "?title=0&byline=0&portrait=0"
                : block.video_url
            }
          />
        </div>
      ) : (
        <div className="relative max-w-screen-xl mx-auto">
          {block.headline?.length > 0 && (
            <div className="absolute top-0 left-0 z-30 w-full pt-10 md:w-1/2 px-grid">
              <h1 className="text-white shadow-sm headline headline-h1">
                {block.headline}
              </h1>
            </div>
          )}
          <Video
            ratio="16by9"
            video_url={
              block.video_url.includes("vimeo.com")
                ? block.video_url + "?title=0&byline=0&portrait=0"
                : block.video_url
            }
          />
        </div>
      )}
    </BlockWrapper>
  )
}

export default VideoHeroBlock
